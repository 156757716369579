import * as React from 'react';
import styles from './zunuMail.module.scss'
import { ReactComponent as PaperFlight } from '../../assets/svg/paper_flight.svg';
import { ReactComponent as CloudDoodle } from '../../assets/svg/cloud_doodle.svg';
import { ReactComponent as UserYellow } from '../../assets/svg/user_yellow_shadow.svg';
import { ReactComponent as UserRed } from '../../assets/svg/user_red_shadow.svg';
import { ReactComponent as CardIcon1 } from '../../assets/svg/mail_card_icon1.svg';
import { ReactComponent as CardIcon2 } from '../../assets/svg/mail_card_icon2.svg';
import { ReactComponent as CardIcon3 } from '../../assets/svg/mail_card_icon3.svg';
import { ReactComponent as CardIcon4 } from '../../assets/svg/mail_card_icon4.svg';
import { ReactComponent as CardIcon5 } from '../../assets/svg/mail_card_icon5.svg';
import { ReactComponent as CardIcon6 } from '../../assets/svg/mail_card_icon6.svg';
import { ReactComponent as CardIcon7 } from '../../assets/svg/mail_card_icon7.svg';
import { ReactComponent as CardIcon8 } from '../../assets/svg/mail_card_icon8.svg';
import { ReactComponent as CardIcon9 } from '../../assets/svg/mail_card_icon9.svg';
import { ReactComponent as CardIcon10 } from '../../assets/svg/mail_card_icon10.svg';
import { ReactComponent as CardIcon11 } from '../../assets/svg/mail_card_icon11.svg';
import { ReactComponent as CardIcon12 } from '../../assets/svg/mail_card_icon12.svg';
import { ReactComponent as CardIcon13 } from '../../assets/svg/mail_card_icon13.svg';
import { ReactComponent as CardIcon14 } from '../../assets/svg/mail_card_icon14.svg';
import USP1 from '../../assets/webp/mail_usp1.webp';
import USP2 from '../../assets/webp/mail_usp2.webp';
import USP3 from '../../assets/webp/mail_usp3.webp';
import USP1Mobile from '../../assets/webp/mail_usp1_mobile.webp';
import USP2Mobile from '../../assets/webp/mail_usp2_mobile.webp';
import USP3Mobile from '../../assets/webp/mail_usp3_mobile.webp';
// import BannerImage from '../../assets/webp/mail_banner_image.webp';
import BannerImage from '../../assets/webp/mail_banner_image_2.webp';
// import BannerImageMobile from '../../assets/webp/mail_banner_image_mobile.webp';
import BannerImageMobile from '../../assets/webp/mail_banner_image_mobile_2.webp';
import SetApaprtImage from '../../assets/webp/mail_setapart_image.webp';
import SetApaprtImageMobile from '../../assets/webp/mail_setapart_image_mobile.webp';
import MailFeat1 from '../../assets/webp/mail_feat1.webp';
import MailFeat2 from '../../assets/webp/mail_feat2.webp';
import MailFeat3 from '../../assets/webp/mail_feat3.webp';
import MailFeat4 from '../../assets/webp/mail_feat4.webp';
import MailFeat5 from '../../assets/webp/mail_feat5.webp';
import MailFeat6 from '../../assets/webp/mail_feat6.webp';
import { useThemeStore } from '../../StateManager/ThemeStore';
import { DEVICE, THEME } from '../../_shared/constants/constants';
import { Helmet } from 'react-helmet';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FAQ } from '../Faq/faq_data';
import { QUESTIONS } from '../Support/questions_data';
import { TESTIMONIALS } from '../Home/components/Testimonials/testimonials_data';
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { Fade, AttentionSeeker, Reveal } from "react-awesome-reveal";
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';
import { WHITEPAPERS } from '../_shared/components/WhitePapers/whitePapersData';
// import { fadeIn, fadeInUp } from '../_shared/components/RevealAnimations/revealAnimations';

//LAZY LOADING
import { lazily } from 'react-lazily';
import { fadeIn, fadeInLeft, fadeInRight, fadeInUp } from '../_shared/components/RevealAnimations/revealAnimations';
const { Testimonials } = lazily(() => import('../Home/components/Testimonials/testimonials'));
const { WhitePapers } = lazily(() => import('../_shared/components/WhitePapers/whitePapers.component'));
const { FloaterDownload } = lazily(() => import('../_shared/components/FloaterDownload/floaterDownload.component'));
const { FloaterNavigator } = lazily(() => import('../_shared/components/floaterNavigator/floaterNavigator.component'));
const { ProductFaqs } = lazily(() => import('../_shared/components/productFaqs/productFaqs.component'));

export function ZunuMail() {

    const { theme } = useThemeStore()
    const { device, setDevice } = useDeviceStore();
    const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();
    const headerRef: any = useRef();

    useEffect(() => {
        if (device != DEVICE.MOBILE) {
            setIsDownloadBtn(true)
            return
        }
        else {
            setIsDownloadBtn(false)
            return
        }
    }, [device])

    return (
        <div className={styles.productPage}>

            <Helmet>
                <meta name="description" content="Your Emails. Your Conversations. Completely Private with Zunu Mail." />
                <title>Zunu Mail - Privacy-First Mailing App.</title>

                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Zunu Mail - Privacy-First Mailing App." />
                <meta property="og:url" content="https://zunuprivacy.com/mail" />
                <meta property="og:description" content="Your Emails. Your Conversations. Completely Private with Zunu Mail.." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Your Emails. Your Conversations. Completely Private with Zunu Mail." />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

            <div className={styles.productIntro}>
                <div className={styles.productIntroText} ref={headerRef}>
                    <Reveal delay={200} triggerOnce duration={1000} keyframes={fadeInUp}>
                        <p>Ultimate Email <span>Privacy</span> on the<br /> Cloud, on your Device and <br /> Everywhere <span>Beyond</span></p>
                    </Reveal>
                    <CloudDoodle className={styles.cloudDoodle} />
                </div>
                <PaperFlight className={styles.paperFlight} />
                <UserYellow className={styles.userYellow} />
                <UserRed className={styles.userRed} />

                <FloaterNavigator />
            </div>

            <FloaterDownload />


            <div className={styles.productUSP}>
                <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                    <p className={styles.uspHeading}>Zunu Mail: Keeps your <span>Emails</span> & Attachments 100% <span>Private</span></p>
                </Reveal>

                <div className={`${styles.uspContainer} ${styles.usp1}`}>
                    <div className={styles.usptextContainer}>
                        <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                            <p className={styles.uspText}>
                                <p>Privacy Risks</p>
                                <p>In <span>Unencrypted</span> Email Services</p>
                            </p>
                        </Reveal>
                        {device != DEVICE.MOBILE &&

                            <div className={styles.uspTextCardsConatiner}>
                                <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>Email service providers <span>read</span> all your emails.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon5 /><p>Email service providers <span>can run harmful</span> AI on your emails & Attachments.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon1 /><p>Once you download attachments, they are <span>not encrypted</span>. Many third-party applications on your phone read them.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon2 /><p>Your confidential data from emails is available for sale on the <span>Dark Web</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Your emails are <span>highly vulnerable</span> in case of device loss, email account hacking, or password compromise.</p></Fade></div>
                                </Fade>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP1}` : `${USP1}`} alt="Zunu Mail USP" fetchPriority='high' />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>Email service providers <span>read</span> all your emails.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon5 /><p>Email service providers <span>can run harmful</span> AI on your emails & Attachments.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon1 /><p>Once you download attachments, they are <span>not encrypted</span>. Many third-party applications on your phone read them.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon2 /><p>Your confidential data from emails is available for sale on the <span>Dark Web</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Your emails are <span>highly vulnerable</span> in case of device loss, email account hacking, or password compromise.</p></Fade></div>
                        </div>
                    }
                </div>

                <div className={`${styles.uspContainer} ${styles.usp2}`}>
                    <div className={styles.usptextContainer}>
                        <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                            <p className={styles.uspText}>
                                <p>Incomplete Protection:</p>
                                <p>Why End-to-End Encryption Leaves Your <span>Emails Vulnerable.</span></p>
                            </p>
                        </Reveal>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon6 /><p>The plain text subject line conveys the email's purpose and <span>main message is read</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon7 /><p>You <span>can't search the encrypted email</span> to find the content you need.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>Once you download the attachments, they are <span>no longer encrypted</span>. Making them easily accessible to many 3rd party apps on your phone.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon2 /><p>Your confidential data from emails and attachments is available for sale on <span>Dark Web</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Your <span>emails are highly vulnerable</span> in case of device loss or when passwords of your email accounts or devices are breached.</p></Fade></div>
                                </Fade>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP2}` : `${USP2}`} alt="Zunu Mail USP" fetchPriority='high' />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon6 /><p>The plain text subject line conveys the email's purpose and <span>main message is read</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon7 /><p>You <span>can't search the encrypted email</span> to find the content you need.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>Once you download the attachments, they are <span>no longer encrypted</span>. Making them easily accessible to many 3rd party apps on your phone.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon2 /><p>Your confidential data from emails and attachments is available for sale on <span>Dark Web</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Your <span>emails are highly vulnerable</span> in case of device loss or when passwords of your email accounts or devices are breached.</p></Fade></div>
                        </div>
                    }
                </div>

                <div className={`${styles.uspContainer} ${styles.usp3}`}>
                    <div className={styles.usptextContainer}>
                        <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                            <p className={styles.uspText}>
                                <p>Complete Privacy</p>
                                <p>for Your Emails with Zunu Mail</p>
                                <p><span className={styles.uspTextGreen}>Device-to-Device End-to-End Encryption.</span></p>
                            </p>
                        </Reveal>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon10 /><p>No email service provider can read your <span>emails</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon9 /><p>Emails have <span>encrypted subject</span>, body, attachments, and drafts.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon11 /><p>Attachments remain <span>encrypted</span> after they are downloaded.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon14 /><p>No 3rd party mobile app can <span>access</span> your attachments.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon13 /><p>Quick retrieval of emails after searching on top of <span>encrypted emails</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon12 /><p>Emails are <span>safe</span> if you lose your phone, or it’s hacked</p></Fade></div>
                                    <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon8 /><p>Your emails are <span>Dark Web Safe</span>.</p></Fade></div>
                                </Fade>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP3}` : `${USP3}`} alt="Zunu Mail USP" fetchPriority='high' />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon10 /><p>No email service provider can read your <span>emails</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon9 /><p>Emails have <span>encrypted subject</span>, body, attachments, and drafts.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon11 /><p>Attachments remain <span>encrypted</span> after they are downloaded.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon14 /><p>No 3rd party mobile app can <span>access</span> your attachments.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon13 /><p>Quick retrieval of emails after searching on top of <span>encrypted emails</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon12 /><p>Emails are <span>safe</span> if you lose your phone, or it’s hacked</p></Fade></div>
                            <div className={styles.uspTextCard}>  <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon8 /><p>Your emails are <span>Dark Web Safe</span>.</p></Fade></div>
                        </div>
                    }
                </div>
            </div>


            <div className={styles.productBanner}>

                {/* <p className={styles.bannerHeading}>No <span>Harmful AI.</span><br></br> No <span>Data Mining.</span><br></br> Your Emails are <span>Dark Web Safe.</span></p> */}
                <p className={styles.bannerHeading}>
                    <Fade cascade damping={.2}  fraction={.2} triggerOnce duration={1500} delay={200}>
                        <p >No <span>Harmful AI.</span></p>
                        <p > No <span>Data Mining.</span></p>
                        <p > Your Emails are <span>Dark Web Safe.</span></p>
                    </Fade>
                </p>


                <figure>
                    <img src={device == DEVICE.MOBILE ? `${BannerImageMobile}` : `${BannerImage}`} alt="Zunu Mail on all Devices" fetchPriority='high' />
                </figure>

            </div>

            {/* <div className={styles.productCardsConatiner}>
                <div className={styles.productCard}>
                    <CardIcon1 />
                    <AttentionSeeker effect='pulse'>
                        <p>Experience Privacy beyond <span>End-to-End Encryption</span></p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon2 />
                    <AttentionSeeker effect='pulse' delay={400}>
                        <p>Add Zunu's <span>100% Privacy</span> to your current Email Address.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon3 />
                    <AttentionSeeker effect='pulse' delay={800}>
                        <p>Search and Quickly Retrieve Emails in the most privacy preserving way.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon4 />
                    <AttentionSeeker effect='pulse' delay={1200}>
                        <p>Emails are <span>Secured</span> from the moment you start composing. Privacy from the Go. </p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon5 />
                    <AttentionSeeker effect='pulse' delay={1600}>
                        <p><span>Emails are private</span> when your phone is lost, its password is compromised, and even when your email account is hacked.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon6 />
                    <AttentionSeeker effect='pulse' delay={2000}>
                        <p><span>Email Confidentiality</span> During Ransomware, Malware and Virus Attacks.</p>
                    </AttentionSeeker>
                </div>
            </div> */}

            <div className={styles.setApart}>
                <div className={styles.setApartHead}>
                    <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                        <p className={styles.heading}>What sets Zunu Mail Apart?</p>
                        <p className={styles.subHeading}>It's ultimate robust <span>Device-to-Device End-to-End Encryption</span> for 100% Privacy.</p>
                    </Reveal>
                </div>
                <figure>
                    <img src={device == DEVICE.MOBILE ? SetApaprtImageMobile : SetApaprtImage} alt="How Zunu Mail works" fetchPriority='high' />
                </figure>
            </div>

            <div className={styles.productFeatures}>
                <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                    <p className={styles.productFeaturesHeading}> Integrate. Encrypt. Email <span>Confidently</span></p>
                </Reveal>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Simplified Privacy: Communicate Privately using your Existing Email Accounts.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Keep your Gmail and Outlook accounts.</li>
                                    <li>Quickly integrate them into Zunu Mail.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={MailFeat2} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>Forget those Passwords</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Add your email accounts to Zunu Mail.</li>
                                    <li>Access them from one place. </li>
                                    <li>No hassle of remembering their passwords.</li>
                                    <li>We're all about convenience.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={MailFeat5} alt="Safe from Dark web" fetchPriority='high' />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Stress-free Email Privacy</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Experience 100% Privacy.</li>
                                    <li>You are in Control.</li>
                                    <li>Keep your emails private from from all email service providers.</li>
                                    <li>Keep your emails private when you lose your device or its password is compromised.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={MailFeat3} alt="Safe from Dark web" fetchPriority='high' />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>Zunu provides Confidentiality to your emails from the Go.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Draft emails without worry</li>
                                    <li>All your unsent emails are also safe.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={MailFeat4} alt="Safe from Dark web" fetchPriority='high' />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Privacy with Zunu is Easy.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Best-in-World Confidentiality, Easy to Use</li>
                                    <li>Search through encrypted emails quickly.</li>
                                    <li>We get it - privacy shouldn't mean sacrificing convenience.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={MailFeat6} alt="Safe from Dark web" fetchPriority='high' />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>Your Emails become AI-safe. <br></br>Dark Web safe.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Your emails are protected from harmful AI algorithms.</li>
                                    <li>Your emails away from the Dark Web's reach.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={MailFeat1} alt="Safe from Dark web" fetchPriority='high' />
                    </figure>
                </div>

            </div>

            <WhitePapers whitepapers={WHITEPAPERS.MAIL} />
            <ProductFaqs data={QUESTIONS.mail.faq} heading="Frequently asked questions" background="#f9f5ff" url="/support?p=mail" />
            {/* <Testimonials data={TESTIMONIALS.MAIL} heading="What our Users say about Zunu Mail" /> */}

        </div>
    )
}