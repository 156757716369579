import * as React from 'react';
import styles from './zunuDrive.module.scss'
import { ReactComponent as FileTransferDoodle } from '../../assets/svg/file_transfer_doodle.svg';
import { ReactComponent as CloudDoodle } from '../../assets/svg/cloud_doodle_shadow.svg';
import { ReactComponent as UserPink } from '../../assets/svg/user_pink_shadow.svg';
import { ReactComponent as UserPurple } from '../../assets/svg/user_purple_shadow.svg';
import { ReactComponent as CardIcon1 } from '../../assets/svg/drive_card_icon1.svg';
import { ReactComponent as CardIcon2 } from '../../assets/svg/drive_card_icon2.svg';
import { ReactComponent as CardIcon3 } from '../../assets/svg/drive_card_icon3.svg';
import { ReactComponent as CardIcon4 } from '../../assets/svg/drive_card_icon4.svg';
import { ReactComponent as CardIcon5 } from '../../assets/svg/drive_card_icon5.svg';
import { ReactComponent as CardIcon6 } from '../../assets/svg/drive_card_icon6.svg';
import { ReactComponent as CardIcon7 } from '../../assets/svg/drive_card_icon7.svg';
import { ReactComponent as CardIcon8 } from '../../assets/svg/drive_card_icon8.svg';
import { ReactComponent as CardIcon9 } from '../../assets/svg/drive_card_icon9.svg';
import { ReactComponent as CardIcon10 } from '../../assets/svg/drive_card_icon10.svg';
import { ReactComponent as CardIcon11 } from '../../assets/svg/drive_card_icon11.svg';
import { ReactComponent as CardIcon12 } from '../../assets/svg/drive_card_icon12.svg';
import { ReactComponent as CardIcon13 } from '../../assets/svg/drive_card_icon13.svg';
import USP1 from '../../assets/webp/drive_usp1.webp';
import USP2 from '../../assets/webp/drive_usp2.webp';
import USP3 from '../../assets/webp/drive_usp3.webp';
import USP1Mobile from '../../assets/webp/drive_usp1_mobile.webp';
import USP2Mobile from '../../assets/webp/drive_usp2_mobile.webp';
import USP3Mobile from '../../assets/webp/drive_usp3_mobile.webp';
// import BannerImage from '../../assets/webp/drive_banner_image.webp';
import BannerImage from '../../assets/webp/drive_banner_image_2.webp';
// import BannerImageMobile from '../../assets/webp/drive_banner_image_mobile.webp';
import BannerImageMobile from '../../assets/webp/drive_banner_image_mobile_2.webp';
import SetApaprtImage from '../../assets/webp/drive_setapart_image.webp';
import SetApaprtImageMobile from '../../assets/webp/drive_setapart_image_mobile.webp';
import DriveFeat1 from '../../assets/webp/drive_feat1.webp';
import DriveFeat2 from '../../assets/webp/drive_feat2.webp';
import DriveFeat3 from '../../assets/webp/drive_feat3.webp';
import DriveFeat4 from '../../assets/webp/drive_feat4.webp';
import DriveFeat5 from '../../assets/webp/drive_feat5.webp';
import DriveFeat6 from '../../assets/webp/drive_feat6.webp';
import DriveFeat7 from '../../assets/webp/drive_feat7.webp';
import DriveFeat8 from '../../assets/webp/drive_feat8.webp';
import DriveFeat9 from '../../assets/webp/drive_feat9.webp';
import DriveFeat10 from '../../assets/webp/drive_feat10.webp';
import { useThemeStore } from '../../StateManager/ThemeStore';
import { DEVICE, THEME } from '../../_shared/constants/constants';
import { Helmet } from 'react-helmet';
import { useEffect, useRef } from 'react';
import { QUESTIONS } from '../Support/questions_data';
import { TESTIMONIALS } from '../Home/components/Testimonials/testimonials_data';
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { Fade, AttentionSeeker, Reveal } from "react-awesome-reveal";
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';
import { WHITEPAPERS } from '../_shared/components/WhitePapers/whitePapersData';

//LAZY LOADING
import { lazily } from 'react-lazily';
import { fadeIn, fadeInLeft, fadeInRight, fadeInUp } from '../_shared/components/RevealAnimations/revealAnimations';
const { Testimonials } = lazily(() => import('../Home/components/Testimonials/testimonials'));
const { WhitePapers } = lazily(() => import('../_shared/components/WhitePapers/whitePapers.component'));
const { FloaterDownload } = lazily(() => import('../_shared/components/FloaterDownload/floaterDownload.component'));
const { FloaterNavigator } = lazily(() => import('../_shared/components/floaterNavigator/floaterNavigator.component'));
const { ProductFaqs } = lazily(() => import('../_shared/components/productFaqs/productFaqs.component'));

export function ZunuDrive() {

    const { theme } = useThemeStore()
    const { device, setDevice } = useDeviceStore();
    const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();
    const headerRef: any = useRef();

    useEffect(() => {
        if (device != DEVICE.MOBILE) {
            setIsDownloadBtn(true)
            return
        }
        else {
            setIsDownloadBtn(false)
            return
        }
    }, [device])

    return (
        <div className={styles.productPage}>

            <Helmet>
                <meta name="description" content="Zunu Drive - Your Files. Your Identity. Under Your Control." />
                <title>Zunu Drive - Your Files. Your Identity. Under Your Control.</title>

                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Zunu Drive - Your Files. Your Identity. Under Your Control." />
                <meta property="og:url" content="https://zunuprivacy.com/mail" />
                <meta property="og:description" content="Zunu Drive - Your Files. Your Identity. Under Your Control." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Zunu Drive - Your Files. Your Identity. Under Your Control." />
                <meta name="twitter:image"
                    content="https://zunuprivacy.com/icon192.png" />
            </Helmet>

            <div className={styles.productIntro}>
                <div className={styles.productIntroText} ref={headerRef}>
                    <Reveal delay={200} triggerOnce duration={1000} keyframes={fadeInUp}>
                        <p>Complete, Consistent, and <br /> Continuous Confidentiality for <br /> your <span>Files.</span></p>
                    </Reveal>
                    <CloudDoodle className={styles.cloudDoodle} />
                    <UserPink className={styles.userPink} />
                </div>
                <FileTransferDoodle className={styles.fileTransferDoodle} />
                <UserPurple className={styles.userPurple} />

                <FloaterNavigator />
            </div>

            <FloaterDownload />


            <div className={styles.productUSP}>
                <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                    <p className={styles.uspHeading}>Zunu Drive: 100% Privacy for Your <span>Files</span><br />On <span>Device</span> and <span>Cloud.</span></p>
                </Reveal>

                <div className={`${styles.uspContainer} ${styles.usp1}`}>
                    <div className={styles.usptextContainer}>
                        <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                            <div className={styles.uspText}>

                                <p>Privacy Risks</p>
                                <p>In <span>Unencrypted</span> Cloud Storage Services.</p>
                            </div>
                        </Reveal>
                        {device != DEVICE.MOBILE &&
                            <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                <div className={styles.uspTextCardsConatiner}>
                                    <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                        <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon2 /><p>Cloud service providers <span>read and run AI</span> on all files backed up, synced, and shared through them.</p></Fade></div>
                                        <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>Cloud storage account <span>compromise risks</span> file privacy, potentially making them accessible on the <span>Dark Web</span>.</p></Fade></div>
                                        <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon1 /><p>Downloaded files from the cloud <span>are stored in the phone's storage</span>, which is accessible to various third-party apps. These apps view and read all files.</p></Fade></div>
                                        <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon5 /><p>Files privacy is at risk if the <span>device is lost</span> or is <span>password is compromised.</span></p> </Fade></div>
                                        <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Files on our devices are vulnerable to <span>ransomware</span>, <span>malware</span>, and <span>virus attacks</span>.</p></Fade></div>
                                    </Fade>
                                </div>
                            </Fade>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP1}` : `${USP1}`} alt="Zunu Mail USP" />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon2 /><p>Cloud service providers <span>read and run AI</span> on all files backed up, synced, and shared through them.</p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>Cloud storage account <span>compromise risks</span> file privacy, potentially making them accessible on the <span>Dark Web</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon1 /><p>Downloaded files from the cloud <span>are stored in the phone's storage</span>, which is accessible to various third-party apps. These apps view and read all files.</p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon5 /><p>Files privacy is at risk if the <span>device is lost</span> or is <span>password is compromised.</span></p> </Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Files on our devices are vulnerable to <span>ransomware</span>, <span>malware</span>, and <span>virus attacks</span>.</p></Fade></div>
                        </div>
                    }
                </div>

                <div className={`${styles.uspContainer} ${styles.usp2}`}>
                    <div className={styles.usptextContainer}>
                        <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                            <div className={styles.uspText}>

                                <p>Why End-to-End Encryption</p>
                                <p>Falls <span>Short for 100% Privacy</span> for Files.</p>
                            </div>
                        </Reveal>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                    <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon6 /><p>Once you download a file shared with you, it <span>loses its encryption</span> on your device, compromising its privacy.</p></Fade></div>
                                    <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon1 /><p>Messaging, banking, e-commerce, and other third-party apps <span>access our device's Storage</span>, enabling them to <span>read</span> all stored files on our device.</p></Fade></div>
                                    <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>When your cloud storage account is <span>compromised</span>, it risks file privacy, potentially making them accessible on the <span>Dark Web</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon5 /><p>Files privacy is at risk if the <span>device is lost</span> or is <span>password compromised.</span></p></Fade></div>
                                    <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Files on our devices are vulnerable to <span>ransomware, malware,</span> and <span>virus attacks.</span></p></Fade></div>
                                </Fade>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP2}` : `${USP2}`} alt="Zunu Mail USP" />
                    </figure>
                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon6 /><p>Once you download a file shared with you, it <span>loses its encryption</span> on your device, compromising its privacy.</p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon1 /><p>Messaging, banking, e-commerce, and other third-party apps <span>access our device's Storage</span>, enabling them to <span>read</span> all stored files on our device.</p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon3 /><p>When your cloud storage account is <span>compromised</span>, it risks file privacy, potentially making them accessible on the <span>Dark Web</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon5 /><p>Files privacy is at risk if the <span>device is lost</span> or is <span>password compromised.</span></p></Fade></div>
                            <div className={styles.uspTextCard}><Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon4 /><p>Files on our devices are vulnerable to <span>ransomware, malware,</span> and <span>virus attacks.</span></p></Fade></div>
                        </div>
                    }
                </div>

                <div className={`${styles.uspContainer} ${styles.usp3}`}>
                    <div className={styles.usptextContainer}>
                        <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                            <div className={styles.uspText}>
                                <p>Ultimate File Privacy with Zunu Drive’s</p>
                                <p><span className={styles.uspTextGreen}>Device-to-Device End-to-End Encryption.</span></p>
                            </div>
                        </Reveal>
                        {device != DEVICE.MOBILE &&
                            <div className={styles.uspTextCardsConatiner}>
                                <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon8 /><p>Cloud Storage service providers <span className={styles.usptextGreen}>cannot read or run AI on your files</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon12 /><p>Files remain <span className={styles.usptextGreen}>Private</span> when your cloud storage account's <span className={styles.usptextGreen}>password is compromised.</span></p></Fade></div>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon13 /><p>Your files are <span className={styles.usptextGreen}>Dark Web safe.</span></p></Fade></div>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon10 /><p>You <span className={styles.usptextGreen}>share files</span> more confidently because they are always under your <span className={styles.usptextGreen}>control.</span></p></Fade></div>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon7 /><p><span className={styles.usptextGreen}>No 3rd party application</span> on your devices can read files stored locally on the device.</p></Fade></div>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon9 /><p>Files remain <span className={styles.usptextGreen}>Private</span> when you lose your device or its <span className={styles.usptextGreen}>password is compromised</span>.</p></Fade></div>
                                    <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon11 /><p>Your <span className={styles.usptextGreen}>files are protected</span> from all Ransomware, Malware, and Virus Attacks.</p></Fade></div>
                                </Fade>
                            </div>
                        }
                    </div>
                    <figure>
                        <img src={device == DEVICE.MOBILE ? `${USP3}` : `${USP3}`} alt="Zunu Mail USP" />
                    </figure>

                    {device == DEVICE.MOBILE &&
                        <div className={styles.uspTextCardsConatiner}>
                            {/* <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce> */}
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon8 /><p>Cloud Storage service providers <span className={styles.usptextGreen}>cannot read or run AI on your files</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon12 /><p>Files remain <span className={styles.usptextGreen}>Private</span> when your cloud storage account's <span className={styles.usptextGreen}>password is compromised.</span></p></Fade></div>
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon13 /><p>Your files are <span className={styles.usptextGreen}>Dark Web safe.</span></p></Fade></div>
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon10 /><p>You <span className={styles.usptextGreen}>share files</span> more confidently because they are always under your <span className={styles.usptextGreen}>control.</span></p></Fade></div>
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon7 /><p><span className={styles.usptextGreen}>No 3rd party application</span> on your devices can read files stored locally on the device.</p></Fade></div>
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon9 /><p>Files remain <span className={styles.usptextGreen}>Private</span> when you lose your device or its <span className={styles.usptextGreen}>password is compromised</span>.</p></Fade></div>
                            <div className={styles.uspTextCard}> <Fade cascade damping={.2} fraction={.2} duration={1000} delay={100} triggerOnce><CardIcon11 /><p>Your <span className={styles.usptextGreen}>files are protected</span> from all Ransomware, Malware, and Virus Attacks.</p></Fade></div>
                            {/* </Fade> */}
                        </div>
                    }
                </div>
            </div>


            <div className={styles.productBanner}>
                <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                    <p className={styles.bannerHeading}>Zunu Drive: Supremacy in File <span>Privacy</span> on Your <span>Devices</span> and <span>Clouds</span></p>
                </Reveal>
                <figure>
                    <img src={device == DEVICE.MOBILE ? `${BannerImageMobile}` : `${BannerImage}`} alt="Zunu Mail on all Devices" />
                </figure>

            </div>

            {/* <div className={styles.productCardsConatiner}>
                <div className={styles.productCard}>
                    <CardIcon1 />
                    <AttentionSeeker effect='pulse'>
                        <p>Experience Privacy beyond <span>End-to-End Encryption</span></p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon2 />
                    <AttentionSeeker effect='pulse' delay={400}>
                        <p>Add Zunu's <span>100% Privacy</span> to your current Email Address.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon3 />
                    <AttentionSeeker effect='pulse' delay={800}>
                        <p>Search and Quickly Retrieve Emails in the most privacy preserving way.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon4 />
                    <AttentionSeeker effect='pulse' delay={1200}>
                        <p>Emails are <span>Secured</span> from the moment you start composing. Privacy from the Go. </p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon5 />
                    <AttentionSeeker effect='pulse' delay={1600}>
                        <p><span>Emails are private</span> when your phone is lost, its password is compromised, and even when your email account is hacked.</p>
                    </AttentionSeeker>
                </div>
                <div className={styles.productCard}>
                    <CardIcon6 />
                    <AttentionSeeker effect='pulse' delay={2000}>
                        <p><span>Email Confidentiality</span> During Ransomware, Malware and Virus Attacks.</p>
                    </AttentionSeeker>
                </div>
            </div> */}

            <div className={styles.setApart}>
                <div className={styles.setApartHead}>
                    <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                        <p className={styles.heading}>What sets Zunu Drive Apart?</p>
                        <p className={styles.subHeading}>It's ultimate robust Device-to-Device End-to-End Encryption for 100% Privacy.</p>
                    </Reveal>
                </div>
                <figure>
                    <img src={device == DEVICE.MOBILE ? `${SetApaprtImageMobile}` : `${SetApaprtImage}`} alt="Zunu Drive explaination" />
                </figure>
            </div>

            <div className={styles.productFeatures}>
                <Reveal keyframes={fadeInUp} triggerOnce duration={1000} delay={200}>
                    <p className={styles.productFeaturesHeading}> Store, Search, Access, Sync, and Share Files with <span>100% Privacy</span></p>
                </Reveal>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Files Hidden from Phone Apps.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Keep your Files Private and Organized on your Device. </li>
                                    <li>Your files are invisible to all those multiple apps having access to your device storage. </li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat1} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>Integrate Clouds and Make Them Private.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Keep using your current cloud storage - Google, Microsoft, Dropbox, Box. </li>
                                    <li>Just give it a Zunu upgrade.</li>
                                    <li>Integrate them with Zunu Drive. </li>
                                    <li>Zunu Drive + Your Clouds = 100% Privacy and ease of use. </li>
                                    <li>Only you hold the keys.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat2} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>One Password Access</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Add your cloud accounts to Zunu Drive. Enjoy streamlined access.</li>
                                    <li>Effortlessly manage multiple accounts in one place. </li>
                                    <li>Eliminates the need to remember passwords.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat3} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>Encrypted Sync</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Your synced files remain confidential.</li>
                                    <li>Your cloud cannot read the files you sync.</li>
                                    <li>Experience total Privacy.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat4} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Files Hidden From Cloud, in Cloud.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Files are confidential within the cloud.</li>
                                    <li>They are off-limits for everyone but you.</li>
                                    <li>Cloud service providers cannot read or run AI on them.</li>
                                    <li>Your files are always private, even in the cloud with Zunu Drive.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat5} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>Ransomware, Malware and Viruses: Access Denied.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Even if you’re hacked, the bad guys get nothing.</li>
                                    <li>They can’t see your files or do anything with your information.</li>
                                    <li>Your Files are fully protected in Zunu.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat6} alt="Safe from Dark web" />
                    </figure>
                </div>


                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Private Share.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Share files and photos fearlessly. </li>
                                    <li>Share files, even with friends who don't have Zunu Drive. </li>
                                    <li>Zunu Drive: Safeguards Your Privacy. Protects Your Loved Ones.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat7} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>File Privacy is Intact when Cloud Account is Compromised.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>No worries if your cloud storage account is compromised.</li>
                                    <li>Or if its password is stolen or lost.</li>
                                    <li>Files in your cloud always remain Private.</li>
                                    <li>They are AI-proof. They are Dark Web safe.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat8} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInLeft}>
                            <p className={styles.featureHeading}>Quick One-Click Recovery</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Instantly recover your files in one Click.</li>
                                    <li>Ransomware, malware, or viruses come what may. Your files are protected. </li>
                                    <li>Be worry-free even when they all get deleted.</li>
                                    <li>Get them back in One Click.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat9} alt="Safe from Dark web" />
                    </figure>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureText}>
                        <Reveal triggerOnce duration={1000} delay={100} keyframes={device == DEVICE.MOBILE ? fadeIn : fadeInRight}>
                            <p className={styles.featureHeading}>File Privacy Intact During Device Loss or Device Password Compromise.</p>

                            <ul>
                                <Fade cascade damping={0.2} delay={100} triggerOnce>
                                    <li>Privacy, even if your device is lost.</li>
                                    <li>Privacy, if the device password is compromised. </li>
                                    <li>Continuous privacy assurance.</li>
                                </Fade>
                            </ul>
                        </Reveal>
                    </div>

                    <figure>
                        <img src={DriveFeat10} alt="Safe from Dark web" />
                    </figure>
                </div>


            </div>

            <WhitePapers whitepapers={WHITEPAPERS.DRIVE} />
            <ProductFaqs data={QUESTIONS.drive.faq} heading="Frequently asked questions" background="#DDE8FF" url="/support?p=drive" />
            {/* <Testimonials data={TESTIMONIALS.DRIVE} heading="What our Users say about Zunu Drive" /> */}

        </div>
    )
}