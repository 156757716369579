import { ZunuThingsCards } from "../ZunuThingsCards/zunuThingsCards";

import { FC, useEffect, useState } from "react";
import { THINGS_ZUNU_DOES, ThingZunuDoesData } from "./content";

import styles from "./zunuThingsCardsContainer.module.scss";
import useAppHook from "../../../../App.hook";
import Carousel from "react-multi-carousel";

// import { ReactComponent as IconLeftArrow } from "../../../../assets/svg/privacy_left_arrow.svg";
// import { ReactComponent as IconRightArrow } from "../../../../assets/svg/privacy_right_arrow.svg";

import { ReactComponent as IconLeftArrow } from "../../../../assets/svg/left_arrow.svg";
import { ReactComponent as IconRightArrow } from "../../../../assets/svg/right_arrow.svg";
import { useDeviceStore } from "../../../../StateManager/DeviceStore";
import { DEVICE } from "../../../../_shared/constants/constants";
import {Reveal, Fade} from "react-awesome-reveal";
import { fadeIn, fadeInLeft, fadeInRight} from "../../../_shared/components/RevealAnimations/revealAnimations";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";


type ZunuThingsCardsContainerProps = {
  isPage?: boolean;
};

const responsive = {
  desktopLarge: {
    breakpoint: { max: 3000, min: 1900 },
    items: 3,
    slidesToSlide: 2,
  },
  desktopMid: {
    breakpoint: { max: 1900, min: 1200 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  desktopSmall: {
    breakpoint: { max: 1199, min: 1025 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 900 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobileLarge: {
    breakpoint: { max: 899, min: 480 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobileMid: {
    breakpoint: { max: 479, min: 361 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 360, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const ZunuThingsCardsContainer: FC<ZunuThingsCardsContainerProps> = ({
  isPage = false,
}) => {
  const [content, setContent] = useState<ThingZunuDoesData[]>(THINGS_ZUNU_DOES);
  const [isCenterMode, setIsCenterMode] = useState<boolean>(false);

  const { theme } = useAppHook();

  const { device } = useDeviceStore();

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 730) {
        setIsCenterMode(true);
      }
    }
  }, []);

  return (
    <div className={styles.zunu_things_cards_container}>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        customTransition="transform 500ms ease-in"
        containerClass={styles.cards_carousel}
        itemClass={styles.carousel_item_style}
        customLeftArrow={
          <button className={styles.carousel_left_arrow} aria-label="left" id="zunuThingsCarouselLeft" disabled>
        <Reveal triggerOnce keyframes={fadeInLeft} duration={1500} delay={200}>
            <IconLeftArrow />
            </Reveal>
          </button>
        }
        customRightArrow={
          <button className={styles.carousel_right_arrow} aria-label="right" id="zunuThingsCarouselRight" disabled>
             <Reveal triggerOnce keyframes={fadeInRight} duration={1500} delay={200}>
            <IconRightArrow />
            </Reveal>
          </button>
        }
        renderArrowsWhenDisabled={true}
        centerMode={device !== DEVICE.MOBILE}
      >
          
        {content.map(({ icon, heading, body, iconDark }, index) => {
          return (
            <ZunuThingsCards
              icon={theme === "light" ? icon : iconDark}
              heading={heading}
              body={body}
              key={index}
            />
          );
        })}
      </Carousel>
    </div>
  );
};
