import { Keyframes, keyframes } from '@emotion/react';

export const fadeInUp = keyframes`
from {
    opacity: 0;
    transform: translateY(20px);
}
to{
    opacity: 1;
    transform: translateY(0);
}
`
export const fadeInLeft = keyframes`
from {
    opacity: 0;
    transform: translateX(-30px);
}
to {
    opacity: 1;
    transform: translateX(0);
}
`;
 export const fadeInRight = keyframes`
from {
    opacity: 0;
    transform: translateX(30px);
}
to {
    opacity: 1;
    transform: translateX(0);
}
`;
export const fadeIn = keyframes`
from{
    opacity: 0;
    transform: translateY(-10px)
}
to{
    opacity: 1;
    transform: translateY(0);
}
`
