import React, { FC } from "react";
import parse from "html-react-parser";
import styles from "./zunuThingsCards.module.scss";
import { Fade } from "react-awesome-reveal";

export type ZunuThingsCardsData = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  heading: string;
  body: string;
};

export const ZunuThingsCards: FC<ZunuThingsCardsData> = ({
  icon: Icon,
  heading,
  body,
}) => {
  return (
    <article className={`${styles.zunu_cards_article}`}>
      <Fade delay={100} fraction={0.2} cascade damping={0.2} duration={1500} triggerOnce >
      <Icon className={styles.article_icon} />
      <h3 className={styles.article_heading}>{parse(heading)}</h3>
      <p className={styles.article_body}>{body}</p>
      </Fade>
    </article>
  );
};
